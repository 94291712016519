<template>
  <div>
    <span>Jivo seller</span>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  created() {
    document.addEventListener('message', () => {
      alert('new Message');
    });
  },
};
</script>
<style>

</style>
